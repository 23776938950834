import React from 'react';
import _ from 'lodash';
import moment from 'moment-strftime';
import styled from 'styled-components';
import { Link } from 'gatsby';

import {Layout} from '../components/index';
import PostCards from '../components/PostCards';
import {safePrefix, htmlToReact } from '../utils';
import SocialIcons from '../components/SocialIcons';

const Container = styled.div`
  background-color: #FAFAFA;
  padding: 30px 4vw;

  @media (min-width: 768px) {
    padding: 80px 4vw;
  }
`;

const Sheet = styled.div`
  background-color: white;
  padding: 50px 20px;
  box-sizing: border-box;
  position: relative;

  @media (min-width: 768px) {
    padding: 70px 70px;
  }

  @media (min-width: 1024px) {
    padding: 70px 100px;
  }

  blockquote {
    color: #2456EA;
  }
`;

const ImgWrapper = styled.div`
  margin: 0 -20px 40px;

  @media (min-width: 768px) {
    margin: 0 -70px 40px;
  }

  @media (min-width: 1024px) {
    margin: 0 -100px 40px;
  }
`;

const AuthorCard = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 48px 1fr 80px;
  grid-column-gap: 1rem;
  text-align: left;

  .author-img {
    height: 48px;
    width: 48px;
    background-size: cover;
    background-position: center center;
    border-radius: 100%;
    grid-row-start: 1;
    grid-row-end: 3;
    align-self: center;
  }

  time {
    opacity: 0.7;
    grid-column-start: 2;
  }
  
  .SocialIcons {
    grid-column-start: 3;
    grid-row-start: 1;
  }

  @media (max-width: 768px) {
    time,
    & > a {
      grid-column-start: 2;
      grid-column-end: 4;
    }

    .SocialIcons {
      grid-column-start: 2;
      grid-row-start: 3;
    }
  }
`;

export default class Post extends React.Component {
  render() {
    const currentBlog = _.get(this.props, 'pageContext.name');
    const pages = _.get(this.props, 'pageContext.pages');
    let sortedPages = _
      .chain(pages)
      .filter({'relativeDir': 'blog'})
      .sortBy('frontmatter.date')
      .value();
    let currentIndex =  _.findIndex(sortedPages, {'name': currentBlog});
    let pageContext = {
      previous: sortedPages[currentIndex - 1],
      next: sortedPages[currentIndex + 1]
    };
    if (pageContext.previous === undefined) {
      pageContext = {
        next: sortedPages[currentIndex + 1]
      };
    } else if (pageContext.next === undefined) {
      pageContext = {
        previous: sortedPages[currentIndex - 1]
      };
    }
    const author = _.get(this.props, 'pageContext.site.data.author.authors').filter(author => author.name === _.get(this.props, 'pageContext.frontmatter.author'))[0] || undefined
    return (
      <Layout {...this.props}>
      <Container>
        <Sheet className="inner-medium">
          <article className="post post-full">
            <header className="post-header">
              <h1 className="post-title">{_.get(this.props, 'pageContext.frontmatter.title')}</h1>
              {author &&
                <AuthorCard style={{marginBottom: "0.8rem"}}>
                  <div className="author-img" style={{ backgroundImage: `url(${author.image})` }}/>
                  <Link to={`/blog?author=` + encodeURI(author.name)}>{author.name + " - " + author.title}</Link>
                  <time className="published"
                    dateTime={moment(_.get(this.props, 'pageContext.frontmatter.date')).strftime('%Y-%m-%d %H:%M')}
                  >
                    {moment(_.get(this.props, 'pageContext.frontmatter.date')).strftime('%A, %B %e, %Y')}
                  </time>
                  <SocialIcons
                    className={'SocialIcons'}
                    twitter={author.twitter}
                    instagram={author.instagram}
                    linkedin={author.linkedin}
                    bg={true}
                  />
                </AuthorCard>
              }
            </header>
            {_.get(this.props, 'pageContext.frontmatter.content_img_path') && 
            <ImgWrapper>
              <img src={safePrefix(_.get(this.props, 'pageContext.frontmatter.content_img_path'))} alt={_.get(this.props, 'pageContext.frontmatter.title')} />
            </ImgWrapper>
            }
            {_.get(this.props, 'pageContext.frontmatter.subtitle') && 
            <div className="post-subtitle">
              {htmlToReact(_.get(this.props, 'pageContext.frontmatter.subtitle'))}
            </div>
            }
            <div className="post-content">
              {htmlToReact(_.get(this.props, 'pageContext.html'))}
            </div>
            <footer className="post-meta">
              <time className="published"
                dateTime={moment(_.get(this.props, 'pageContext.frontmatter.date')).strftime('%Y-%m-%d %H:%M')}
              >
                {moment(_.get(this.props, 'pageContext.frontmatter.date')).strftime('%A, %B %e, %Y')}
              </time>
            </footer>
          </article>
          {_.get(this.props, 'pageContext.frontmatter.tags') && 
              _.get(this.props, 'pageContext.frontmatter.tags').map(cat => (
                <Link to={`/blog?category=${cat}`} style={{marginRight: "0.4rem", marginBottom: "0.4rem", fontSize: "0.8rem", backgroundColor: "rgb(36 86 234 / 24%)", padding: "0.4rem 0.8rem", borderRadius: "8px", display: "inline-block"}}>{cat}</Link>
              ))
          }
        </Sheet>
        <section className="outer">
          <div className="inner-medium">
            <h2>Continue Reading</h2>
            <PostCards posts={pageContext}/>
          </div>
        </section>
      </Container>
      </Layout>
    );
  }
}
